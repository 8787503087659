import { ReactNode } from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Spinner } from 'Components';
import useAppContext from 'contexts/AppContext';

import type { ButtonTypes } from 'types';

type ButtonProps = {
  as?: 'button' | 'submit' | 'reset';
  type?: ButtonTypes;
  children: ReactNode | string;
  className?: string[];
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: any;
  externalHref?: string;
  targetBlank?: boolean;
  to?: string;
  icon?: string;
  dataTestId?: string;
  padding?: string[];
  fullWidth?: boolean;
};

export function Button(props: ButtonProps) {
  const { tenant } = useAppContext();
  const { colors } = tenant;

  const primary = [
    'tw-text-white',
    'hover:tw-text-white',
    'focus:tw-text-white',
    'active:tw-scale-90',
    'disabled:tw-scale-100',
    'tw-rounded',
    'tw-border-transparent',
    'tw-no-underline',
    'tw-font-bold',
    `tw-bg-${colors.primary.brand.base}`,
    `hover:tw-bg-${colors.primary.brand.dark}`,
    `active:tw-bg-${colors.primary.brand.dark}`,
    `disabled:tw-bg-${colors.secondary.inactive.light}`,
    `disabled:hover:tw-bg-${colors.secondary.inactive.light}`,
  ];

  const secondary = [
    'tw-font-bold',
    'tw-bg-white',
    'tw-rounded-xl',
    'tw-border',
    `tw-text-${colors.primary.brand.base}`,
    `tw-border-${colors.primary.brand.base}`,
    'active:tw-scale-90',
    'disabled:tw-scale-100',
    'tw-no-underline',
    `hover:tw-bg-${colors.primary.brand.lightest}`,
    `hover:tw-text-${colors.primary.brand.dark}`,
    `hover:tw-border-${colors.primary.brand.dark}`,
    `disabled:tw-border`,
    `disabled:tw-border-${colors.secondary.inactive.light}`,
    `disabled:tw-text-${colors.secondary.inactive.light}`,
    `disabled:hover:tw-bg-white`,
  ];

  const tertiary = [
    `tw-text-${colors.secondary.action.base}`,
    `hover:tw-text-${colors.secondary.action.dark}`,
    'tw-font-bold',
    'hover:tw-underline',
    'active:tw-scale-90',
    'disabled:tw-scale-100',
    `disabled:tw-text-${colors.secondary.inactive.light}`,
    `disabled:hover:tw-text-${colors.secondary.inactive.light}`,
    'disabled:hover:tw-no-underline',
  ];

  const redPrimary = [
    'tw-text-white',
    'hover:tw-text-white',
    'tw-font-bold',
    'tw-rounded-xl',
    'tw-font-semibold',
    'tw-bg-red-arrow',
    'hover:tw-bg-red-darker',
    'active:tw-bg-red-darker',
    'active:tw-scale-90',
    'disabled:tw-scale-100',
    'tw-no-underline',
    'tw-px-5',
    'tw-py-3',
  ];

  const redSecondary = [
    'tw-font-bold',
    'tw-bg-white',
    'tw-rounded-xl',
    'tw-border',
    'tw-text-red-arrow',
    'tw-border-red-arrow',
    'active:tw-scale-90',
    'disabled:tw-scale-100',
    'tw-no-underline',
    'tw-px-5',
    'tw-py-3',
    'tw-text-red-arrow',
    'tw-border-red-arrow',
    'hover:tw-bg-red-lightest',
    'hover:tw-border-red-darker',
    'hover:tw-text-red-darker',
  ];

  const blueSquare = [
    'tw-text-white',
    'tw-rounded-xl',
    'hover:tw-text-white',
    'focus:tw-text-white',
    'tw-no-underline',
    'tw-whitespace-nowrap',
    'tw-px-4',
    'tw-py-2',
    'tw-text-blue-link',
    'tw-border-blue-link',
    'tw-border',
    'hover:tw-bg-blue-link',
    'focus:tw-bg-blue-link',
  ];

  const quaternary = [
    `tw-text-${colors.primary.body.base}`,
    `hover:tw-text-${colors.primary.body.dark}`,
    'tw-font-bold',
    'hover:tw-underline',
    'active:tw-scale-90',
    'disabled:tw-scale-100',
    `disabled:tw-text-${colors.secondary.inactive.light}`,
  ];

  const designs = {
    primary,
    secondary,
    redPrimary,
    redSecondary,
    tertiary,
    squared: [],
    link: [
      'hover:tw-underline',
      'tw-text-blue-link',
      'tw-text-base',
      'tw-font-bold',
      'hover:tw-text-blue-link-hover',
      'active:tw-scale-90',
    ],
    whiteLink: ['tw-underline', 'tw-text-white'],
    greyLink: ['tw-underline', 'tw-text-gray-ravenswood'],
    redLink: ['tw-underline', 'tw-text-red-arrow'],
    blueSquare,
    login: [...redPrimary, 'tw-w-full', 'lg:tw-w-2/3', 'tw-text-xl'],
    signup: [
      ...primary,
      'tw-bg-red-arrow',
      'hover:tw-bg-red-darker',
      'focus:tw-bg-red-darker',
      'tw-w-full',
      'lg:tw-w-auto',
      'tw-py-4',
      'tw-px-9',
      'tw-font-bold',
      'tw-text-xl',
    ],
    quaternary,
  };

  let paddingDefault: string[] = [];
  if (props.type) {
    if (props.type === 'login') {
      paddingDefault = ['tw-px-5', 'tw-py-3'];
    } else if (['primary', 'secondary', 'redPrimary'].includes(props.type)) {
      paddingDefault = ['tw-px-5', 'tw-py-3'];
    }
  }

  const {
    as = 'button',
    type = 'primary',
    children,
    className,
    isDisabled = false,
    isLoading = false,
    onClick,
    externalHref,
    to,
    icon,
    dataTestId,
    padding = paddingDefault,
    targetBlank = true,
    fullWidth,
  } = props;

  const styles = [
    {
      'tw-cursor-not-allowed': isLoading,
    },
    ...designs[type],
    ...padding,
    'disabled:tw-cursor-not-allowed',
    'tw-inline-block',
    'tw-inline-flex',
    'tw-justify-center',
    'tw-cursor-pointer',
    className,
  ];
  if (type === 'greyLink') {
    if (isLoading) {
      styles.push(...['tw-bg-neutral-200', 'hover:tw-bg-neutral-200']);
    } else {
      styles.push('hover:tw-bg-neutral-100');
    }
  }
  if (fullWidth) {
    styles.push('tw-w-full');
  }

  const classList = classNames(styles);

  if (to) {
    return (
      <Link
        to={to}
        className={classList}
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick();
          }
        }}
        data-testid={dataTestId}
      >
        {children}
        {icon && <img src={icon} alt="" className="tw-ml-2" />}
      </Link>
    );
  }
  if (externalHref) {
    const aProps: { [key: string]: string } = {
      href: externalHref,
      className: classList,
      rel: 'noreferrer',
      onClick,
    };
    if (dataTestId) {
      aProps['data-testid'] = dataTestId;
    }
    if (typeof targetBlank === 'boolean' && targetBlank) {
      aProps.target = '_blank';
    }
    return <a {...aProps}>{children}</a>;
  }
  type ButtonProps = {
    type?: 'button' | 'submit' | 'reset';
    className: string;
    onClick: () => void;
    'data-testid'?: string;
    disabled?: boolean;
    width?: string;
  };
  const buttonProps: ButtonProps = {
    type: as,
    className: classList,
    onClick,
    'data-testid': dataTestId,
  };
  if (isDisabled) {
    buttonProps.disabled = isDisabled;
  }
  return (
    <button {...buttonProps}>
      {isLoading ? (
        <div className="tw-text-xl tw-contents">
          <Spinner type={type} />
          <span className="tw-ml-2">Loading ...</span>
        </div>
      ) : (
        children
      )}
    </button>
  );
}
